import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "serviceContent" }
const _hoisted_3 = { class: "serviceName" }
const _hoisted_4 = {
  key: 0,
  class: "programStart"
}

import { computed } from 'vue'
import u from '@/utils'
import { ProgramInfoProps } from '@/definitions/general';

export default /*@__PURE__*/_defineComponent({
  __name: 'EpgListItem',
  props: {
    info: {}
  },
  setup(__props: any) {

const props = __props


const startTime = computed<string>(() => {
	let time = props.info.start;
	if (!time) {
		return '';
	}
	const date = new Date(time);
	const d = u.twoDigit(date.getDate());
	const m = date.toLocaleString(window.navigator.language, {
		month: 'short'
	});
	const y = date.getFullYear();
	const h = u.twoDigit(date.getHours());
	const min = u.twoDigit(date.getMinutes());

	return `${d}. ${m} ${y} ${h}.${min}`;
})

const disabled = computed<boolean>(() => {
	const start = props.info.start;
	if (!start) {
		return false;
	}
	const date = new Date(start);
	if (new Date() < date) {
		return true;
	}
	return false;
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`service ${disabled.value ? 'disabled' : ''}`)
  }, [
    _createElementVNode("img", {
      src: props.info.logo,
      alt: "",
      class: "serviceLogo"
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(props.info.name), 1),
      (startTime.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(startTime.value), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})