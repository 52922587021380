


function twoDigit(value: number): string | number {
	return value < 10 ? `0${value}` : value;
}
const u = {
	twoDigit,
	apiurl: 'https://api.powernet.tv/iptv/app'
}

export default u