<template>
    <div class="personal">
        <header>
            <SearchMain
                :filters="false"
                :groupToggle="true"
                :onSearch="handleSearch"
                :placeholder="searchPlaceholder"
            />
        </header>
        <Transition>
            <ContentCategory
                class="watchline"
                title="Se videre"
                v-if="
                    group == 'watchlist' && watchingPrograms.length > 0
                        ? true
                        : false
                "
            >
                <ProgramCard
                    v-for="program in watchingPrograms"
                    :key="program._id"
                    :program="{
                        ...program,
                        thumbnail: `https://image.powernet.tv/${program.imageIds?.[0]}?width=300&height=220`
                    }"
                    @click="
                        () => {
                            openPlayer(program, true);
                        }
                    "
                />
            </ContentCategory>
        </Transition>
        <h1 class="groupHeading">
            {{
                group == '' || group == 'personal'
                    ? 'Mine optagelser'
                    : group == 'family'
                    ? 'Husstandens optagelser'
                    : 'Min liste'
            }}
        </h1>
        <TransitionGroup tag="div" name="channels" class="personalMain">
            <ProgramCard
                v-for="program in programs"
                :key="program._id"
                :program="{
                    ...(group == 'personal' ||
                    group == '' ||
                    group == 'watchlist'
                        ? {
                              ...program,
                              profile: null,
                              thumbnail: `https://image.powernet.tv/${program.imageIds?.[0]}?width=300&height=220`
                          }
                        : {
                              ...program,
                              thumbnail: `https://image.powernet.tv/${program.imageIds?.[0]}?width=300&height=220`
                          })
                }"
                :profiles="profiles"
                :profileEditable="group == 'family' ? true : false"
                @click="
                    () => {
                        openPlayer(program);
                    }
                "
            />
        </TransitionGroup>
        <div class="spaceLeft">
            <p>
                Husstanden har brugt {{ usedStorage }} af
                {{ totalStorage }} timers optagelse
            </p>
            <ProgressBar :customWidth="`${usedStorage}`" />
        </div>
    </div>
    <Transition>
        <Player
            v-if="player"
            :streamId="selectedRecording?.epgList?.[0]?._id"
            :closeOnMenuClose="false"
            :defaultProgram="selectedRecording"
            :onClose="closePlayer"
            :startConfig="{
                programInfo: true,
                programGuide: false,
                channelGuide: false
            }"
            :live="false"
            :mode="
                customPlayerGroup
                    ? customPlayerGroup
                    : group == 'watchlist'
                    ? 'streaming'
                    : 'recording'
            "
            :autoplay="false"
        />
    </Transition>
</template>

<script>
import ProgramCard from '../components/programCard.vue';
import axios from 'axios';
import ProgressBar from '../components/ProgressBar.vue';
import SearchMain from '../components/Search.vue';
import Player from '@/components/Player.vue';
import emitter from 'tiny-emitter/instance';
import ContentCategory from '@/components/ContentCategory.vue';
import message from '@/components/statusMessage';
export default {
    name: 'PersonalMain',
    components: {
        ProgramCard,
        ProgressBar,
        SearchMain,
        Player,
        ContentCategory
    },
    data() {
        return {
            programs: [],
            storage: false,
            totalStorage: 0,
            usedStorage: 0,
            player: false,
            selectedRecording: {},
            searchTimer: null,
            group: 'personal',
            searchPlaceholder: 'Søg i dine optagelser',
            placeholders: {
                personal: 'Søg i dine optagelser',
                family: 'Søg i alle optagelser',
                watchlist: 'Søg i din liste'
            },
            watchLine: true,
            watchingPrograms: [],
            customPlayerGroup: '',
            profiles: []
        };
    },
    mounted() {
        this.fetchRecordings({
            group: 'personal'
        });
        this.fetchProfiles();
        emitter.on('profileChange', this.profileChanged);
    },
    beforeUnmount() {
        emitter.off('profileChange', this.profileChanged);
    },
    watch: {
        group: function () {
            this.programs = [];
        }
    },
    methods: {
        profileChanged() {
            this.fetchRecordings({ group: this.group });
        },
        fetchProfiles() {
            let token = window.localStorage.getItem('token');
            if (!token) return;
            let dataUrl = `${this.$store.state.apiUrl}drmprofile?token=${token}`;
            axios
                .get(dataUrl)
                .then((res) => {
                    this.profiles = res.data;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        openPlayer(program, archive) {
            if (archive) {
                this.customPlayerGroup = 'archive';
            }
            this.selectedRecording = program;
            this.player = true;
        },
        closePlayer() {
            this.player = false;
            this.selectedRecording = {};
            if (this.group == 'watchlist') {
                this.fetchRecordings({ group: 'watchlist' });
            }
        },
        fetchRecordings({ group, keyword }) {
            let self = this;
            if (keyword) {
                this.watchLine = '';
            } else {
                if (group == 'watchlist') {
                    this.fetchWatching(group);
                }
            }
            let localStorage = window.localStorage;
            let token = localStorage.getItem('token');
            let profile = localStorage.getItem('lastProfile');
            let dataUrl = '';
            if (group == 'personal' && profile) {
                dataUrl = `${self.$store.state.apiUrl}recording?token=${token}&profile=${profile}`;
            } else if (group == 'family') {
                dataUrl = `${self.$store.state.apiUrl}recording?token=${token}`;
            } else if (group == 'watchlist') {
                if (!profile) {
                    message.Error('Kan ikke hente din liste uden en profil');
                } else {
                    dataUrl = `${self.$store.state.apiUrl}watchlist/${profile}?token=${token}`;
                }
            }
            dataUrl = `${dataUrl}${keyword ? `&title=${keyword}` : ''}`;
            if (!dataUrl) return;
            axios
                .get(dataUrl)
                .then(({ data }) => {
                    if (data) {
                        if (group != 'watchlist') {
							for (const program of data.epg) {
								program.recording = true;
							}
                            self.programs = data.epg;
                        } else {
                            let programs = data.watchList;
                            for (let i = 0; i < programs.length; i++) {
                                const program = programs[i];
                                if (program) {
                                    let programId = program._id;
                                    program.watchList = true;
                                    program.programId = programId;
                                    if (
                                        program.epgList &&
                                        program.epgList.length > 0
                                    ) {
                                        program._id = program.epgList[0]._id;
                                    }
                                }
                            }
                            self.programs = programs;
                        }
                    }
                    if (data.used || data.used == 0) {
                        self.usedStorage = (data.used / 60).toFixed(1);
                    }
                    if (data.storage || data.storage == 0) {
                        self.totalStorage = data.storage;
                    }
                })
                .catch((err) => {
                    console.error(err);
                    self.$store.dispatch('validateLogin');
                });
        },
        handleSearch(searchParams) {
            let self = this;
            this.group = searchParams.group;
            this.searchPlaceholder = this.placeholders[searchParams.group];
            if (this.searchTimer) {
                clearTimeout(this.searchTimer);
                this.searchTimer = setTimeout(function () {
                    self.fetchRecordings(searchParams);
                }, 300);
            } else {
                this.searchTimer = setTimeout(function () {
                    self.fetchRecordings(searchParams);
                }, 300);
            }
        },
        fetchWatching(group) {
            if (group == this.watchLine) return;
            let localStorage = window.localStorage;
            let token = localStorage.getItem('token');
            let profile = localStorage.getItem('lastProfile');
            let dataUrl = `${this.$store.state.apiUrl}multiepg`;
            let body = {
                watching: true
            };
            body.token = token;
            if (['personal', 'watchlist'].includes(group)) {
                body.profile = profile;
                this.watchLine = 'personal';
            } else {
                this.watchLine = 'family';
            }
            axios
                .post(dataUrl, body, { 'Content-Type': 'application/json' })
                .then(({ data }) => {
                    this.watchingPrograms = data;
                });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/components/variables.scss';
.personal {
    padding: 0 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    header {
        .storageDisplay {
            width: 100%;
            text-align: left;
        }
        .searchWrapper {
            display: flex;
            justify-content: flex-end;
        }
    }
    .watchline {
        margin-top: 20px;
        max-height: 500px;
        overflow-y: hidden;
        transition-duration: 0.4s !important;
        &.v-leave-to,
        &.v-enter-from {
            max-height: 0;
        }
    }
    .groupHeading {
        align-self: flex-start;
        margin-top: 20px;
    }
    .personalMain {
        display: grid;
        padding-top: 20px;
        gap: 40px 20px;
        position: relative;
        max-width: max-content;
        padding: 20px 20px;
        @include responsive-grid;
        margin: 0 auto;
    }
    .spaceLeft {
        position: fixed;
        bottom: 50px;
        max-width: 80%;
        width: 500px;
        margin: 0 auto;
        padding: 15px 30px 20px 30px;
        border-radius: 20px;
        z-index: 9;
        background-color: rgba(var(--primaryBackground), 0.9);
    }
}
</style>
