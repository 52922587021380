const message = {
    Error: function (errorMessage: string, customRoot?: HTMLElement) {
        const root = customRoot ? customRoot : getRoot();
        const elem = document.createElement('div');
        elem.className = "statusMessage";
        const content = `
        <div class="inner">
            <svg id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <path id="cross" d="M16,4A12,12,0,1,0,28,16,12.01343,12.01343,0,0,0,16,4Zm0,21a9,9,0,1,1,9-9A9.01016,9.01016,0,0,1,16,25Zm5.30328-11.47485L18.82843,16l2.47485,2.47485a.50007.50007,0,0,1,0,.70716L19.182,21.30328a.50007.50007,0,0,1-.70716,0L16,18.82843l-2.47485,2.47485a.50007.50007,0,0,1-.70716,0L10.69672,19.182a.50007.50007,0,0,1,0-.70716L13.17157,16l-2.47485-2.47485a.50007.50007,0,0,1,0-.70716L12.818,10.69672a.50007.50007,0,0,1,.70716,0L16,13.17157l2.47485-2.47485a.50007.50007,0,0,1,.70716,0L21.30328,12.818A.50007.50007,0,0,1,21.30328,13.52515Z"/>
            </svg>
            <span>${errorMessage}</span>
        </div>
        `;
        elem.insertAdjacentHTML('beforeend', content);
        root.insertAdjacentElement('beforeend', elem);
        setTimeout(function () {
            elem.classList.add('active');
        }, 10)
        setTimeout(function () {
            elem.classList.remove('active');
        }, 3000);
        setTimeout(function () {
            elem.remove();
        }, 3500)
    },
    Success: function (successMessage: string, customRoot?: HTMLElement) {
        const root = customRoot ? customRoot : getRoot();
        const elem = document.createElement('div');
        elem.className = "statusMessage";
        const content = `
        <div class="inner">
            <svg id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <path id="check" d="M16,4A12,12,0,1,0,28,16,12.01343,12.01343,0,0,0,16,4Zm0,21a9,9,0,1,1,9-9A9.01016,9.01016,0,0,1,16,25Zm6.05774-12.70874a.5.5,0,0,1,.03088.67883l-6.81109,8.05823a1,1,0,0,1-1.50623.02429L9.88147,16.741a.5.5,0,0,1,.00616-.67658l1.137-1.21508a.5.5,0,0,1,.63354-.0802l2.611,1.66156,6.03095-5.22522a.5.5,0,0,1,.6784.02178Z"/>
            </svg>
            <span>${successMessage}</span>
        </div>
        `;
        elem.insertAdjacentHTML('beforeend', content);
        root.insertAdjacentElement('beforeend', elem);
        setTimeout(function () {
            elem.classList.add('active');
        }, 10)
        setTimeout(function () {
            elem.classList.remove('active');
        }, 3000);
        setTimeout(function () {
            elem.remove();
        }, 3500)
    }
}

function getRoot() {
    const container = document.querySelector('div.appWrapper');
    if (container) {
        return container;
    } else {
        return document.body;
    }
}
export default message;