<template>
    <div class="programInfo playerMenu">
		<div class="header" :style="{ backgroundImage: secondaryProgramImage ? '' : `url(${primaryProgramPoster})` }">
			<div class="content">
				<img class="secondaryImage" v-if="secondaryProgramImage" :src="secondaryProgramImage" alt="">
				<div>
					<h1 class="programTitle">{{ programData.title }}</h1>
					<p class="genres" v-if="programData.genres && programData.genres.length">
						{{ programData.genres.join(', ') }} 
						&bull; 
						{{ programData.duration }} min. 
						<span class="programEpisode" v-if="programHasSeriesData">&bull; {{ programSeriesInfo }}</span>
					</p>
				</div>
			</div>
			<ProgressBar class="progressBar" v-if="programData.position > 10" :customWidth="`${programData.position / programData.duration}`"/>
			<div v-if="props.programMode == 'recording' || programData.recording" class="programLock" :class="{locked: programData.favorite}" @click="lockProgram">
                <svg v-if="programData.favorite" id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                    <path id="lock" d="M25,12H24V9a5.00588,5.00588,0,0,0-5-5H13A5.00588,5.00588,0,0,0,8,9v3H7a2.00591,2.00591,0,0,0-2,2V26a2.00591,2.00591,0,0,0,2,2H25a2.00591,2.00591,0,0,0,2-2V14A2.00591,2.00591,0,0,0,25,12Zm-8,8.7226V23.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V20.7226a2,2,0,1,1,2,0ZM20,12H12V9a1.01348,1.01348,0,0,1,1-1h6a1.01348,1.01348,0,0,1,1,1Z"/>
                </svg>
                <svg v-else id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
					<path id="lock-open" d="M25,12H24V5a5.00588,5.00588,0,0,0-5-5H13A5.00588,5.00588,0,0,0,8,5V7h4V5a1.01348,1.01348,0,0,1,1-1h6a1.01348,1.01348,0,0,1,1,1v7H7a2.00591,2.00591,0,0,0-2,2V26a2.00591,2.00591,0,0,0,2,2H25a2.00591,2.00591,0,0,0,2-2V14A2.00591,2.00591,0,0,0,25,12Zm-8,8.7226V23.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V20.7226a2,2,0,1,1,2,0Z"/>
                </svg>
            </div>
		</div>
		<div class="main">

			<div class="actionButtons" :class="{ startOverHidden: !showStartOver }">
				<button v-if="showStartOver" class="btn actionButton startOver" @click="props.onPlayToggle(programData, null, true)">
					Se forfra
				</button>
				<button v-if="isPlayable" class="btn actionButton play" :class="{ playing: props.playingId == programData._id && props.playing }" @click="playFromPlayButton">
					{{ playingLabel }}
				</button>
				<button class="btn actionButton list" :class="{onList: programData.watchList}" @click="handleList">
					{{ watchlistLabel }}</button
				>
				<button v-if="isRecordable" class="btn actionButton record" :class="{ recording: typeof programData.recording != 'undefined' ? programData.recording : props.programMode == 'recording' ? true : false }" @click="handleRecord">
					<svg id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
						<path id="voicemail" d="M23,10a5.98922,5.98922,0,0,0-5.18823,9H14.18823A5.995,5.995,0,1,0,9,22H23a6,6,0,0,0,0-12ZM9,19a3,3,0,1,1,3-3A3.00328,3.00328,0,0,1,9,19Zm14,0a3,3,0,1,1,3-3A3.00328,3.00328,0,0,1,23,19Z"/>
					</svg>
					{{ recordingLabel }}
				</button>
			</div>
			<EpgListItem
				v-if="firstEpg"
				:info="{
					name: firstEpg.channelName,
					logo: firstEpg.channelLogo || firstEpg.logo,
					start: firstEpg.start
				}"
				style="pointer-events: none; margin-bottom: 10px;"
			/>
			<p class="description">{{ programData.description }}</p>
			<SettingCategory heading="Kanaler" :description="`Andre kanaler hvor du kan se ${programData.title}`" :style="{ margin: '20px 0' }" v-if="showOtherEpgs">
				<div class="services channels">
					<EpgListItem
						v-for="service in playableEpgs"
						:key="service._id"
						:info="{
							name: service.channelName,
							logo: service.channelLogo,
							start: service.start
						}"
						:disabled="new Date() < new Date(service.start)"
						@click="playFromEpgListItem(service)"
					/>
				</div>
			</SettingCategory>
			<SettingCategory v-if="programHasStreams" heading="Streaming tjenester" :description="`Andre tjenester hvor du kan se ${programData.title}`">
				<div class="services">
					<EpgListItem
						:info="{
							name: streamingService.name,
							logo: streamingService.logo
						}"
						v-for="streamingService in programData.streams"
						:key="streamingService._id"
						@click="openLink(streamingService.url)"
					/>
				</div>
			</SettingCategory>
		</div>
    </div>
</template>

<script setup lang="ts">
import { computed, defineProps, defineModel, ref, onMounted } from 'vue'
import axios from 'axios';
import message from '@/components/statusMessage';
import SettingCategory from '@/components/SettingCategory.vue';
import EpgListItem from '@/components/EpgListItem.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import store from '@/store';
import u from '@/utils';

const props = defineProps<{
	playingId: String
	playing: Boolean
	onPlayToggle: Function
	onList: Function
	programMode: String
	programData: any
	onRecordEpgListItem: Function
	onLock: Function
}>()

const programData = defineModel<any>()
const secondaryProgramImage = ref<string>('')

const primaryProgramPoster = computed<string>(() => {
	try {
		const imageId = programData.value.imageIds[0]
		return `https://image.powernet.tv/${imageId}?width=400&height=250`
	} catch(err) {
		return ''
	}
})
const programHasStreams = computed<boolean>(() => {
	return programData.value.streams && programData.value.streams.length > 0 && !props.playingId
})
const programHasSeriesData = computed<boolean>(() => {
	return programData.value.series && programData.value.series.season && programData.value.series.episode
})
const programSeriesInfo = computed<string>(() => {
	return `S${programData.value.series.season} E${programData.value.series.episode}`
})
const showStartOver = computed<boolean>(() => {
	if (programData.value.position > 10 && props.programMode != 'channel') {
		return true;
	}
	if (props.programMode == 'channel') {
		return true;
	}
	return false;
})
const programDataIsAProgram = computed<boolean>(() => {
	return !programData.value.start && props.programMode == 'streaming' && programData.value.epgList && programData.value.epgList.length > 0
})
const playMode = computed<string>(() => {
	if (props.programMode != 'streaming') {
		if (programData.value.start && new Date() < new Date(programData.value.start)) {
			return 'archive';
		}
	}
	if (props.programMode == 'channel') {
		if (new Date() < new Date(programData.value.end)) {
			return 'channel';
		} else {
			return 'archive';
		}
	}
	if (props.programMode == 'recording') {
		return 'recording';
	}
	if (props.programMode == 'streaming') {
		return 'archive';
	}
	if (props.programMode == 'archive') {
		return 'archive';
	}
	return '';
})
const isPlayable = computed(() => {
	let p = programData.value;
	let bool = true;
	switch (props.programMode) {
		case 'channel':
			if (new Date(p.start) > new Date()) {
				bool = false;
			}
			break;
		case 'archive':
			if (new Date(p.start) > new Date()) {
				bool = false;
				break;
			}
			if (
				p.epgList &&
				![...p.epgList].filter((o) => {
					return new Date(o.start) < new Date();
				}).length
			) {
				bool = false;
			}
			break;
		case 'streaming':
			if (!p.epgList || p.epgList.length < 1) {
				bool = false;
			}
			if (
				p.epgList &&
				!p.epgList.filter((o: any) => new Date(o.start) < new Date())
					.length
			) {
				bool = false;
			}
			break;
		case 'recording':
			if (new Date(p.start) > new Date()) {
				bool = false;
			}
			if (p.expire && new Date(p.expire) < new Date()) {
				bool = false;
			}
			break;
	}

	return bool;
})
const playingLabel = computed<string>(() => {
	if (props.playingId == programData.value._id) {
		if (props.playing) {
			return 'Pause';
		} else {
			if (programData.value.position > 10) {
				return 'Se videre';
			} else {
				return 'Afspil';
			}
		}
	}
	if (programData.value.position > 10) {
		return 'Se videre';
	} else {
		return 'Afspil';
	}
})
const watchlistLabel = computed<string>(() => {
	return programData.value.watchList ? '- Min liste' : '+ Min liste'
})
const firstEpg = computed<any>(() => {
	try {
		if (programData.value.epgList) {
			if (programData.value.epgList.length > 0) {
				return programData.value.epgList[0]
			}
			return null
		}
		if (programData.value._id == '0_0') {
			return null
		}
		return programData.value
	} catch(err) {
		return null
	}
})
const isRecordable = computed<boolean>(() => {
	if (props.programMode != 'streaming' || (programData.value.epgList && programData.value.epgList.length > 0)) {
		return true
	}
	return false
})
const recordingLabel = computed<string>(() => {
	if (typeof programData.value.recording != 'undefined') {
		if (programData.value.recording) {
			return 'Annuller optagelse'
		} else {
			return 'Optag'
		}
	} else {
		if (props.programMode == 'recording') {
			return 'Annuller optagelse'
		} else {
			return 'Optag'
		}
	}
})
const showOtherEpgs = computed<boolean>(() => {
	if (!programData.value.epgList) return false
	if (programData.value.epgList.length < 2) return false
	if (props.playingId) return false
	if (props.programMode == 'recording') return false
	const filteredList = programData.value.epgList.filter((o: any) => {
		return o.channelId != firstEpg.value.channelId
	})
	if (filteredList.length < 1) return false
	return true
})
const playableEpgs = computed<any[]>(() => {
	const epgList = programData.value.epgList
	if (!epgList) return []
	return epgList.filter((o: any, index: number) => {
		if (index != 0 || new Date(o.start) > new Date()) {
			return o
		}
	})
})


onMounted(() => {
	setSecondaryProgramImage()
})


async function setSecondaryProgramImage() {
	const img = new Image()
	img.onload = () => {
		if (img.height > img.width) {
			secondaryProgramImage.value = img.src
		}
	}
	if (programData.value.imageIds.length > 1) {
		const imageId = programData.value.imageIds[1]
		img.src = `https://image.powernet.tv/${imageId}?width=150`
	}
}
function openLink(url: string) {
	window.open(url, '_blank')
}
async function handleList() {
	const program = programData.value;
	const mode = program.watchList ? 'delete' : 'add';
	const token = localStorage.getItem('token');
	const profile = localStorage.getItem('lastProfile');
	const programId = program.programId ? program.programId : program._id;
	const dataUrl = `${store.state.apiUrl}watchlist/${profile}/${programId}?token=${token}`;
	if (mode == 'add') {
		try {
			await axios.post(dataUrl,{})
			props.onList(true)
		} catch(err) {
			console.error(err);
			message.Error(
				'Kunne ikke tilføje programmet til din liste'
			);

		}
	} else if (mode == 'delete') {
		try {
			await axios.delete(dataUrl)
			props.onList(false);
		} catch(err) {
			console.error(err);
			message.Error(
				'Kunne ikke fjerne programmet fra din liste'
			);
		}

	}
}
function playFromPlayButton() {
	const program = programDataIsAProgram.value ? {
		...programData.value.epgList[0],
		position:
		programData.value.position > 10
			? programData.value.position
			: 0
		} : programData.value;
	props.onPlayToggle(program, playMode.value);
}
function getProfileForRecording(mode: 'add' | 'delete') {
	const lastProfile = localStorage.getItem('lastProfile');
	if (mode == 'delete' && props.programMode == 'recording') {
		if (programData.value.profile) {
			return programData.value.profile._id;
		}
		return lastProfile || null;
	} else {
		return lastProfile || null;
	}
}
function lockProgram() {
	let lockState = programData.value.favorite;
	let token = window.localStorage.getItem('token');
	let profile = programData.value.profile
		? programData.value.profile._id
		: null;
	const epgId = props.programMode == 'streaming' ? programData.value?.epgList?.[0]?._id : programData.value._id
	if (!token) return;
	let dataUrl = `${u.apiurl}/recording/${epgId}/favorite?token=${token}&mode=${!lockState}${
		profile ? `&profile=${profile}` : ''
	}`;
	axios
		.get(dataUrl)
		.then(() => {
			props.onLock(!lockState);
		})
		.catch((err) => {
			console.error(err);
			message.Error('Kunne ikke låse optagelsen');
		});
}
async function handleRecord() {
	const mode = programData.value.recording ? 'delete' : 'add';
	try {
		const profile = getProfileForRecording(mode)
		const epgId = props.programMode == 'streaming' ? programData.value?.epgList?.[0]?._id : programData.value._id
		const token = localStorage.getItem('token')
		const profileAddOn = profile ? `&profile=${profile}` : ''
		const dataUrl = `${u.apiurl}/recording/${epgId}/${mode}?token=${token}${profileAddOn}`;
		const res = await axios.get(dataUrl)
		const data = res.data
		if (data.success == true) {
			if (mode == 'add') {
				programData.value.recording = true;
			} else {
				programData.value.recording = false;
			}
		}
			
	} catch(err) {	
		console.error(err);
		message.Error('Der skete en uventet fejl');
	}
}
function playFromEpgListItem(service: any) {
	if (new Date() > new Date(service.start)) {
		props.onPlayToggle(service, 'archive');
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/variables.scss';
@import "@/scss/button.scss";
.programInfo {
    width: 30rem;
    top: 30px;
    right: 30px;
    bottom: 30px;
    background-color: rgb(var(--primaryBackground));
    border-radius: 20px;
    overflow: scroll;
	.header {
		aspect-ratio: 16 / 9;
		background-size: cover;
		background-position: center;
		display: flex;
		align-items: flex-end;
		padding: 10px;
		position: relative;
		* {
			position: relative;
		}
		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(
				180deg,
				rgba(var(--primaryBackground), 0) 0%,
				rgba(var(--primaryBackground), 0.5) 50%,
				rgba(var(--primaryBackground), 1) 100%
			);
		}
		.content {
			display: flex;
			align-items: flex-end;
			gap: 10px;
			height: 100%;
		}
		.secondaryImage {
			border-radius: 10px;
		}
		.programLock {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            background-color: rgba(var(--secondaryBackground), 0.5);
            border-radius: 10px;
            padding: 5px;
            width: 35px;
            height: 35px;
            &.locked {
                background-color: rgb(var(--themeColor));
                svg {
                    path {
                        fill: rgb(var(--primaryTextColor));
                    }
                }
            }
            svg {
                height: 100%;
                width: 100%;
                path {
                    fill: rgb(var(--themeColor));
                }
            }
        }
	}
	.main {
		padding: 10px;
	}
    .poster {
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
        height: max-content;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        
        .posterImg {
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        .progressBar {
            position: absolute;
            bottom: 10px;
            left: 20px;
            right: 20px;
            width: unset;
        }
    }
    .programTitle {
        display: inline;
    }
    .programEpisode {
        margin-left: 10px;
        color: rgb(var(--secondaryTextColor));
        font-size: 13px;
        font-weight: bold;
    }
    .actionButtons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'startOver play' 'list record';
        gap: 10px;
        width: 100%;
        flex-wrap: wrap;
        margin: 10px 0;
        &.startOverHidden {
            grid-template-areas: 'play play' 'list record';
        }
        button {
            border-radius: 5px;
            font-weight: bold;
            font-size: 14px;
            flex-shrink: 0;
            transition: background-color 0.1s linear, color 0.1s linear,
                box-shadow 0.1s ease;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            svg {
                height: 20px;
                width: 20px;
            }
            &:hover {
                box-shadow: $defaultShadow;
            }
            &.play {
                background-color: rgb(var(--themeColor));
                color: rgb(var(--primaryTextColor));
                width: 100%;
                grid-area: play;
                &.playing {
                    background-color: rgb(var(--secondaryBackground));
                    color: rgb(var(--themeColor));
                }
            }
            &.startOver {
                background-color: rgb(var(--themeColor));
                color: rgb(var(--primaryTextColor));
                grid-area: startOver;
            }
            &.list {
                background-color: rgb(var(--secondaryBackground));
                color: rgb(var(--themeColor));
                grid-area: list;
                &.onList {
                    background-color: rgb(var(--themeColor));
                    color: rgb(var(--primaryTextColor));
                }
            }
            &.record {
                background-color: rgb(var(--secondaryBackground));
                color: rgb(var(--themeColor));
                grid-area: record;
                svg {
                    path {
                        transition: fill 0.1s linear;
                        fill: rgb(var(--themeColor));
                    }
                }
                &.recording {
                    background-color: rgb(var(--themeColor));
                    color: rgb(var(--primaryTextColor));
                    svg {
                        path {
                            fill: rgb(var(--primaryTextColor));
                        }
                    }
                }
            }
        }
    }
    .services {
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
        &.channels {
            img.serviceLogo {
                object-fit: contain;
            }
        }
    }
}
</style>
