import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "programInfo playerMenu" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "programTitle" }
const _hoisted_5 = {
  key: 0,
  class: "genres"
}
const _hoisted_6 = {
  key: 0,
  class: "programEpisode"
}
const _hoisted_7 = {
  key: 0,
  id: "glyphicons-basic",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 32 32"
}
const _hoisted_8 = {
  key: 1,
  id: "glyphicons-basic",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 32 32"
}
const _hoisted_9 = { class: "main" }
const _hoisted_10 = { class: "description" }
const _hoisted_11 = { class: "services channels" }
const _hoisted_12 = { class: "services" }

import { computed, ref, onMounted } from 'vue'
import axios from 'axios';
import message from '@/components/statusMessage';
import SettingCategory from '@/components/SettingCategory.vue';
import EpgListItem from '@/components/EpgListItem.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import store from '@/store';
import u from '@/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'programInfo',
  props: /*@__PURE__*/_mergeModels({
    playingId: {},
    playing: {},
    onPlayToggle: { type: Function },
    onList: { type: Function },
    programMode: {},
    programData: {},
    onRecordEpgListItem: { type: Function },
    onLock: { type: Function }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const props = __props

const programData = _useModel<any>(__props, "modelValue")
const secondaryProgramImage = ref<string>('')

const primaryProgramPoster = computed<string>(() => {
	try {
		const imageId = programData.value.imageIds[0]
		return `https://image.powernet.tv/${imageId}?width=400&height=250`
	} catch(err) {
		return ''
	}
})
const programHasStreams = computed<boolean>(() => {
	return programData.value.streams && programData.value.streams.length > 0 && !props.playingId
})
const programHasSeriesData = computed<boolean>(() => {
	return programData.value.series && programData.value.series.season && programData.value.series.episode
})
const programSeriesInfo = computed<string>(() => {
	return `S${programData.value.series.season} E${programData.value.series.episode}`
})
const showStartOver = computed<boolean>(() => {
	if (programData.value.position > 10 && props.programMode != 'channel') {
		return true;
	}
	if (props.programMode == 'channel') {
		return true;
	}
	return false;
})
const programDataIsAProgram = computed<boolean>(() => {
	return !programData.value.start && props.programMode == 'streaming' && programData.value.epgList && programData.value.epgList.length > 0
})
const playMode = computed<string>(() => {
	if (props.programMode != 'streaming') {
		if (programData.value.start && new Date() < new Date(programData.value.start)) {
			return 'archive';
		}
	}
	if (props.programMode == 'channel') {
		if (new Date() < new Date(programData.value.end)) {
			return 'channel';
		} else {
			return 'archive';
		}
	}
	if (props.programMode == 'recording') {
		return 'recording';
	}
	if (props.programMode == 'streaming') {
		return 'archive';
	}
	if (props.programMode == 'archive') {
		return 'archive';
	}
	return '';
})
const isPlayable = computed(() => {
	let p = programData.value;
	let bool = true;
	switch (props.programMode) {
		case 'channel':
			if (new Date(p.start) > new Date()) {
				bool = false;
			}
			break;
		case 'archive':
			if (new Date(p.start) > new Date()) {
				bool = false;
				break;
			}
			if (
				p.epgList &&
				![...p.epgList].filter((o) => {
					return new Date(o.start) < new Date();
				}).length
			) {
				bool = false;
			}
			break;
		case 'streaming':
			if (!p.epgList || p.epgList.length < 1) {
				bool = false;
			}
			if (
				p.epgList &&
				!p.epgList.filter((o: any) => new Date(o.start) < new Date())
					.length
			) {
				bool = false;
			}
			break;
		case 'recording':
			if (new Date(p.start) > new Date()) {
				bool = false;
			}
			if (p.expire && new Date(p.expire) < new Date()) {
				bool = false;
			}
			break;
	}

	return bool;
})
const playingLabel = computed<string>(() => {
	if (props.playingId == programData.value._id) {
		if (props.playing) {
			return 'Pause';
		} else {
			if (programData.value.position > 10) {
				return 'Se videre';
			} else {
				return 'Afspil';
			}
		}
	}
	if (programData.value.position > 10) {
		return 'Se videre';
	} else {
		return 'Afspil';
	}
})
const watchlistLabel = computed<string>(() => {
	return programData.value.watchList ? '- Min liste' : '+ Min liste'
})
const firstEpg = computed<any>(() => {
	try {
		if (programData.value.epgList) {
			if (programData.value.epgList.length > 0) {
				return programData.value.epgList[0]
			}
			return null
		}
		if (programData.value._id == '0_0') {
			return null
		}
		return programData.value
	} catch(err) {
		return null
	}
})
const isRecordable = computed<boolean>(() => {
	if (props.programMode != 'streaming' || (programData.value.epgList && programData.value.epgList.length > 0)) {
		return true
	}
	return false
})
const recordingLabel = computed<string>(() => {
	if (typeof programData.value.recording != 'undefined') {
		if (programData.value.recording) {
			return 'Annuller optagelse'
		} else {
			return 'Optag'
		}
	} else {
		if (props.programMode == 'recording') {
			return 'Annuller optagelse'
		} else {
			return 'Optag'
		}
	}
})
const showOtherEpgs = computed<boolean>(() => {
	if (!programData.value.epgList) return false
	if (programData.value.epgList.length < 2) return false
	if (props.playingId) return false
	if (props.programMode == 'recording') return false
	const filteredList = programData.value.epgList.filter((o: any) => {
		return o.channelId != firstEpg.value.channelId
	})
	if (filteredList.length < 1) return false
	return true
})
const playableEpgs = computed<any[]>(() => {
	const epgList = programData.value.epgList
	if (!epgList) return []
	return epgList.filter((o: any, index: number) => {
		if (index != 0 || new Date(o.start) > new Date()) {
			return o
		}
	})
})


onMounted(() => {
	setSecondaryProgramImage()
})


async function setSecondaryProgramImage() {
	const img = new Image()
	img.onload = () => {
		if (img.height > img.width) {
			secondaryProgramImage.value = img.src
		}
	}
	if (programData.value.imageIds.length > 1) {
		const imageId = programData.value.imageIds[1]
		img.src = `https://image.powernet.tv/${imageId}?width=150`
	}
}
function openLink(url: string) {
	window.open(url, '_blank')
}
async function handleList() {
	const program = programData.value;
	const mode = program.watchList ? 'delete' : 'add';
	const token = localStorage.getItem('token');
	const profile = localStorage.getItem('lastProfile');
	const programId = program.programId ? program.programId : program._id;
	const dataUrl = `${store.state.apiUrl}watchlist/${profile}/${programId}?token=${token}`;
	if (mode == 'add') {
		try {
			await axios.post(dataUrl,{})
			props.onList(true)
		} catch(err) {
			console.error(err);
			message.Error(
				'Kunne ikke tilføje programmet til din liste'
			);

		}
	} else if (mode == 'delete') {
		try {
			await axios.delete(dataUrl)
			props.onList(false);
		} catch(err) {
			console.error(err);
			message.Error(
				'Kunne ikke fjerne programmet fra din liste'
			);
		}

	}
}
function playFromPlayButton() {
	const program = programDataIsAProgram.value ? {
		...programData.value.epgList[0],
		position:
		programData.value.position > 10
			? programData.value.position
			: 0
		} : programData.value;
	props.onPlayToggle(program, playMode.value);
}
function getProfileForRecording(mode: 'add' | 'delete') {
	const lastProfile = localStorage.getItem('lastProfile');
	if (mode == 'delete' && props.programMode == 'recording') {
		if (programData.value.profile) {
			return programData.value.profile._id;
		}
		return lastProfile || null;
	} else {
		return lastProfile || null;
	}
}
function lockProgram() {
	let lockState = programData.value.favorite;
	let token = window.localStorage.getItem('token');
	let profile = programData.value.profile
		? programData.value.profile._id
		: null;
	const epgId = props.programMode == 'streaming' ? programData.value?.epgList?.[0]?._id : programData.value._id
	if (!token) return;
	let dataUrl = `${u.apiurl}/recording/${epgId}/favorite?token=${token}&mode=${!lockState}${
		profile ? `&profile=${profile}` : ''
	}`;
	axios
		.get(dataUrl)
		.then(() => {
			props.onLock(!lockState);
		})
		.catch((err) => {
			console.error(err);
			message.Error('Kunne ikke låse optagelsen');
		});
}
async function handleRecord() {
	const mode = programData.value.recording ? 'delete' : 'add';
	try {
		const profile = getProfileForRecording(mode)
		const epgId = props.programMode == 'streaming' ? programData.value?.epgList?.[0]?._id : programData.value._id
		const token = localStorage.getItem('token')
		const profileAddOn = profile ? `&profile=${profile}` : ''
		const dataUrl = `${u.apiurl}/recording/${epgId}/${mode}?token=${token}${profileAddOn}`;
		const res = await axios.get(dataUrl)
		const data = res.data
		if (data.success == true) {
			if (mode == 'add') {
				programData.value.recording = true;
			} else {
				programData.value.recording = false;
			}
		}
			
	} catch(err) {	
		console.error(err);
		message.Error('Der skete en uventet fejl');
	}
}
function playFromEpgListItem(service: any) {
	if (new Date() > new Date(service.start)) {
		props.onPlayToggle(service, 'archive');
	}
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "header",
      style: _normalizeStyle({ backgroundImage: secondaryProgramImage.value ? '' : `url(${primaryProgramPoster.value})` })
    }, [
      _createElementVNode("div", _hoisted_2, [
        (secondaryProgramImage.value)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "secondaryImage",
              src: secondaryProgramImage.value,
              alt: ""
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createElementVNode("h1", _hoisted_4, _toDisplayString(programData.value.title), 1),
          (programData.value.genres && programData.value.genres.length)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                _createTextVNode(_toDisplayString(programData.value.genres.join(', ')) + " • " + _toDisplayString(programData.value.duration) + " min. ", 1),
                (programHasSeriesData.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, "• " + _toDisplayString(programSeriesInfo.value), 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      (programData.value.position > 10)
        ? (_openBlock(), _createBlock(ProgressBar, {
            key: 0,
            class: "progressBar",
            customWidth: `${programData.value.position / programData.value.duration}`
          }, null, 8, ["customWidth"]))
        : _createCommentVNode("", true),
      (props.programMode == 'recording' || programData.value.recording)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["programLock", {locked: programData.value.favorite}]),
            onClick: lockProgram
          }, [
            (programData.value.favorite)
              ? (_openBlock(), _createElementBlock("svg", _hoisted_7, _cache[1] || (_cache[1] = [
                  _createElementVNode("path", {
                    id: "lock",
                    d: "M25,12H24V9a5.00588,5.00588,0,0,0-5-5H13A5.00588,5.00588,0,0,0,8,9v3H7a2.00591,2.00591,0,0,0-2,2V26a2.00591,2.00591,0,0,0,2,2H25a2.00591,2.00591,0,0,0,2-2V14A2.00591,2.00591,0,0,0,25,12Zm-8,8.7226V23.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V20.7226a2,2,0,1,1,2,0ZM20,12H12V9a1.01348,1.01348,0,0,1,1-1h6a1.01348,1.01348,0,0,1,1,1Z"
                  }, null, -1)
                ])))
              : (_openBlock(), _createElementBlock("svg", _hoisted_8, _cache[2] || (_cache[2] = [
                  _createElementVNode("path", {
                    id: "lock-open",
                    d: "M25,12H24V5a5.00588,5.00588,0,0,0-5-5H13A5.00588,5.00588,0,0,0,8,5V7h4V5a1.01348,1.01348,0,0,1,1-1h6a1.01348,1.01348,0,0,1,1,1v7H7a2.00591,2.00591,0,0,0-2,2V26a2.00591,2.00591,0,0,0,2,2H25a2.00591,2.00591,0,0,0,2-2V14A2.00591,2.00591,0,0,0,25,12Zm-8,8.7226V23.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V20.7226a2,2,0,1,1,2,0Z"
                  }, null, -1)
                ])))
          ], 2))
        : _createCommentVNode("", true)
    ], 4),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", {
        class: _normalizeClass(["actionButtons", { startOverHidden: !showStartOver.value }])
      }, [
        (showStartOver.value)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn actionButton startOver",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (props.onPlayToggle(programData.value, null, true)))
            }, " Se forfra "))
          : _createCommentVNode("", true),
        (isPlayable.value)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: _normalizeClass(["btn actionButton play", { playing: props.playingId == programData.value._id && props.playing }]),
              onClick: playFromPlayButton
            }, _toDisplayString(playingLabel.value), 3))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          class: _normalizeClass(["btn actionButton list", {onList: programData.value.watchList}]),
          onClick: handleList
        }, _toDisplayString(watchlistLabel.value), 3),
        (isRecordable.value)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: _normalizeClass(["btn actionButton record", { recording: typeof programData.value.recording != 'undefined' ? programData.value.recording : props.programMode == 'recording' ? true : false }]),
              onClick: handleRecord
            }, [
              _cache[3] || (_cache[3] = _createElementVNode("svg", {
                id: "glyphicons-basic",
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 32 32"
              }, [
                _createElementVNode("path", {
                  id: "voicemail",
                  d: "M23,10a5.98922,5.98922,0,0,0-5.18823,9H14.18823A5.995,5.995,0,1,0,9,22H23a6,6,0,0,0,0-12ZM9,19a3,3,0,1,1,3-3A3.00328,3.00328,0,0,1,9,19Zm14,0a3,3,0,1,1,3-3A3.00328,3.00328,0,0,1,23,19Z"
                })
              ], -1)),
              _createTextVNode(" " + _toDisplayString(recordingLabel.value), 1)
            ], 2))
          : _createCommentVNode("", true)
      ], 2),
      (firstEpg.value)
        ? (_openBlock(), _createBlock(EpgListItem, {
            key: 0,
            info: {
					name: firstEpg.value.channelName,
					logo: firstEpg.value.channelLogo || firstEpg.value.logo,
					start: firstEpg.value.start
				},
            style: {"pointer-events":"none","margin-bottom":"10px"}
          }, null, 8, ["info"]))
        : _createCommentVNode("", true),
      _createElementVNode("p", _hoisted_10, _toDisplayString(programData.value.description), 1),
      (showOtherEpgs.value)
        ? (_openBlock(), _createBlock(SettingCategory, {
            key: 1,
            heading: "Kanaler",
            description: `Andre kanaler hvor du kan se ${programData.value.title}`,
            style: { margin: '20px 0' }
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(playableEpgs.value, (service) => {
                  return (_openBlock(), _createBlock(EpgListItem, {
                    key: service._id,
                    info: {
							name: service.channelName,
							logo: service.channelLogo,
							start: service.start
						},
                    disabled: new Date() < new Date(service.start),
                    onClick: ($event: any) => (playFromEpgListItem(service))
                  }, null, 8, ["info", "disabled", "onClick"]))
                }), 128))
              ])
            ]),
            _: 1
          }, 8, ["description"]))
        : _createCommentVNode("", true),
      (programHasStreams.value)
        ? (_openBlock(), _createBlock(SettingCategory, {
            key: 2,
            heading: "Streaming tjenester",
            description: `Andre tjenester hvor du kan se ${programData.value.title}`
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(programData.value.streams, (streamingService) => {
                  return (_openBlock(), _createBlock(EpgListItem, {
                    info: {
							name: streamingService.name,
							logo: streamingService.logo
						},
                    key: streamingService._id,
                    onClick: ($event: any) => (openLink(streamingService.url))
                  }, null, 8, ["info", "onClick"]))
                }), 128))
              ])
            ]),
            _: 1
          }, 8, ["description"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})