<template>
    <div :class="`service ${disabled ? 'disabled' : ''}`">
        <img :src="props.info.logo" alt="" class="serviceLogo" />
        <div class="serviceContent">
            <h3 class="serviceName">{{ props.info.name }}</h3>
            <p v-if="startTime" class="programStart">{{ startTime }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'
import u from '@/utils'
import { ProgramInfoProps } from '@/definitions/general';
const props = defineProps<ProgramInfoProps>()


const startTime = computed<string>(() => {
	let time = props.info.start;
	if (!time) {
		return '';
	}
	const date = new Date(time);
	const d = u.twoDigit(date.getDate());
	const m = date.toLocaleString(window.navigator.language, {
		month: 'short'
	});
	const y = date.getFullYear();
	const h = u.twoDigit(date.getHours());
	const min = u.twoDigit(date.getMinutes());

	return `${d}. ${m} ${y} ${h}.${min}`;
})

const disabled = computed<boolean>(() => {
	const start = props.info.start;
	if (!start) {
		return false;
	}
	const date = new Date(start);
	if (new Date() < date) {
		return true;
	}
	return false;
})

</script>

<style lang="scss" scoped>
.service {
    display: flex;
    background-color: rgb(var(--secondaryBackground));
    border-radius: 10px;
    overflow: hidden;
    height: 70px;
    width: 100%;
    transition: background-color;
    transition-duration: 0.15s;
    transition-timing-function: ease;
    cursor: pointer;
    .serviceContent {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;
        text-align: start;

        .serviceName {
            font-size: 22px;
            transition: color;
            transition-duration: 0.15s;
            transition-timing-function: ease;
        }
        .programStart {
            font-size: 15px;
            transition: color;
            transition-duration: 0.15s;
            transition-timing-function: ease;
        }
    }
    img.serviceLogo {
        height: 100%;
        width: auto;
        background-color: white;
        border-radius: 10px;
        overflow: hidden;
    }
    &:not(.disabled):hover {
        background-color: rgb(var(--primaryTextColor));
        .serviceContent {
            .serviceName {
                color: rgb(var(--primaryBackground));
            }
            .programStart {
                color: rgb(var(--secondaryBackground));
            }
        }
    }
    &.disabled {
        filter: grayscale(1);
    }
}
</style>