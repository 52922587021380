import {Router, RouteRecordRaw} from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import TvMain from '@/views/Tv.vue';
import GuideMain from '@/views/Guide.vue';
import PersonalMain from '@/views/Personal.vue';
import loginMain from '@/views/Login.vue';
import StreamingMain from '@/views/Streaming.vue';
import SettingsMain from '@/views/Settings.vue';
import ProfileSettings from '@/views/ProfileSettings.vue';
import UserSettings from '@/views/UserSettings.vue';
import profileChannels from '@/components/profileChannels.vue';
import profileStreaming from '@/components/profileStreaming.vue';
import UserDevices from '@/components/UserDevices.vue';
import LogOnByCode from '@/components/LogOnByCode.vue';
const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'tv',
    component: TvMain
  },
  {
    path: '/login',
    component: loginMain
  },
  {
    path: '/guide',
    name: 'guide',
    component: GuideMain
  },
  {
    path: '/streaming',
    name: 'streaming',
    component: StreamingMain
  },
  {
    path: '/personal',
    name: 'personal',
    component: PersonalMain
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsMain,
    children: [
      {
        path: '',
        name: 'userSettings',
        component: UserSettings,
        children: [
          {
            path: 'devices',
            name: 'userDevices',
            component: UserDevices
          }
        ]
      },
      {
        path: 'profile/:id/',
        name: 'profileSettings',
        component: ProfileSettings,
        children: [
          {
            path: 'channels',
            name: 'profileChannels',
            component: profileChannels
          },
          {
            path: 'streaming',
            name: 'profileStreaming',
            component: profileStreaming
          }
        ]
      }
    ]
  },
  {
    path: '/logonbycode',
    name: 'logonbycode',
    component: LogOnByCode
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
